var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "bookReverseModal",
          attrs: {
            id: "modal-tall",
            size: "lg",
            title: "Book Reverse Shipment",
            "hide-footer": true,
            "modal-class": "book-modal"
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function(ref) {
                var close = ref.close
                return [
                  _c("span", { staticClass: "d-block fs18 lh28 fw500 mt-8" }, [
                    _vm._v(
                      " Book-Reverse Shipment - " +
                        _vm._s(_vm.bookReverseShipmentData.order.importedID) +
                        " "
                    )
                  ]),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "rounded" },
                      on: {
                        click: function($event) {
                          return close()
                        }
                      }
                    },
                    [_c("i", { staticClass: "icon-close-variant fs14" })]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.isOpen,
            callback: function($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen"
          }
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.formSubmit($event)
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "span",
                    { staticClass: "fs18 lh28 fw500 d-block pb-38 pb-md-24" },
                    [_vm._v(" Order Information ")]
                  )
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "warehouse-field",
                          label: "Warehouse",
                          "label-for": "warehouse",
                          description: _vm.form.sellerAddress
                            ? _vm.form.sellerAddress.address
                            : ""
                        }
                      },
                      [
                        _c("multiselect", {
                          ref: "warehouse",
                          attrs: {
                            id: "warehouse",
                            options: _vm.rebookWarehouseOptions,
                            label: "name",
                            "show-labels": false
                          },
                          model: {
                            value: _vm.form.sellerAddress,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "sellerAddress", $$v)
                            },
                            expression: "form.sellerAddress"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-md-6" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "orderid-field",
                          label: "Order ID",
                          "label-for": "order-id"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "order-id",
                            placeholder: "Enter order id",
                            pattern: "[0-9a-z#A-Z_ /-]*",
                            minlength: "2",
                            maxlength: "35",
                            title: "Allowed special characters are # - / _",
                            required: ""
                          },
                          model: {
                            value: _vm.form.orderId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "orderId", $$v)
                            },
                            expression: "form.orderId"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 col-md-6" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "date-field",
                          label: "Date",
                          "label-for": "date"
                        }
                      },
                      [
                        _c(
                          "date-picker",
                          {
                            ref: "orderDate",
                            attrs: {
                              "prefix-class": "xmx",
                              id: "date",
                              placeholder: "yyyy-mm-dd"
                            },
                            model: {
                              value: _vm.form.orderDate,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "orderDate", $$v)
                              },
                              expression: "form.orderDate"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "icon-calendar",
                              attrs: { slot: "icon-calendar" },
                              slot: "icon-calendar"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-md-6" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "type-field",
                          label: "Order Type",
                          "label-for": "Order Type"
                        }
                      },
                      [
                        _c("multiselect", {
                          attrs: {
                            id: "type",
                            value: "REVERSE",
                            options: _vm.types,
                            disabled: "",
                            readonly: "true",
                            searchable: false,
                            "show-labels": false
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 col-md-6" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "total-value-field",
                          label: "Total Value",
                          "label-for": "total-value"
                        }
                      },
                      [
                        _c(
                          "b-input-group",
                          { attrs: { append: "₹" } },
                          [
                            _c("b-input", {
                              attrs: {
                                id: "total-value",
                                placeholder: "0.00",
                                title: "Only numeric or decimal values allowed",
                                type: "number",
                                min: "0.01",
                                step: "0.01",
                                required: ""
                              },
                              model: {
                                value: _vm.form.totalValue,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "totalValue", $$v)
                                },
                                expression: "form.totalValue"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm.$store.getters.user.choice == 0
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "type-field",
                              label: "Mode Type",
                              "label-for": "Mode Type"
                            }
                          },
                          [
                            _c("multiselect", {
                              ref: "modeType",
                              attrs: {
                                id: "type",
                                label: "name",
                                required: "",
                                options: _vm.modeType,
                                searchable: false,
                                "show-labels": false
                              },
                              model: {
                                value: _vm.form.modeType,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "modeType", $$v)
                                },
                                expression: "form.modeType"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "fs18 lh28 fw500 d-block pt-16 pb-38 pb-md-24"
                    },
                    [_vm._v(" Customer Information ")]
                  )
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "customer-name-field",
                          label: "Name",
                          "label-for": "customer-name"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "customer-name",
                            placeholder: "Enter customer name",
                            required: ""
                          },
                          model: {
                            value: _vm.form.customerName,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "customerName", $$v)
                            },
                            expression: "form.customerName"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "phone-field",
                          label: "Phone Number",
                          "label-for": "phone-number"
                        }
                      },
                      [
                        _c(
                          "b-input-group",
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "phone-number",
                                placeholder: "123 456 7890",
                                type: "tel",
                                maxlength: "10",
                                minlength: "10",
                                formatter: _vm.numbers,
                                oninvalid:
                                  "this.setCustomValidity('Enter valid mobile number.')",
                                oninput: "this.setCustomValidity('')",
                                required: ""
                              },
                              model: {
                                value: _vm.form.customerContact,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "customerContact", $$v)
                                },
                                expression: "form.customerContact"
                              }
                            }),
                            _c("div", { staticClass: "input-group-append" }, [
                              _c("div", { staticClass: "input-group-text" }, [
                                _c("i", {
                                  staticClass: "icon-phone fs16 text-gray-600"
                                })
                              ])
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "city-field",
                          label: "City",
                          "label-for": "city"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "city",
                            placeholder: "Enter city",
                            maxlength: "35",
                            required: ""
                          },
                          model: {
                            value: _vm.form.customerCity,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "customerCity", $$v)
                            },
                            expression: "form.customerCity"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              this.maxLength
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-9" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "address-field",
                              label: "Full Address",
                              "label-for": "address",
                              description:
                                "Characters: " +
                                _vm.form.customerAddress.length +
                                " / " +
                                this.maxLength
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "address",
                                placeholder: "Enter customer address",
                                required: "",
                                maxlength: this.maxLength
                              },
                              model: {
                                value: _vm.form.customerAddress,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "customerAddress", $$v)
                                },
                                expression: "form.customerAddress"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-3" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "pin-field",
                              label: "Pin Code",
                              "label-for": "pin"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "pin",
                                placeholder: "110001",
                                type: "text",
                                maxlength: "6",
                                minlength: "6",
                                oninvalid:
                                  "this.setCustomValidity('Enter valid pincode.')",
                                oninput: "this.setCustomValidity('')",
                                formatter: _vm.numbers,
                                required: ""
                              },
                              model: {
                                value: _vm.form.customerPinCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "customerPinCode", $$v)
                                },
                                expression: "form.customerPinCode"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "fs18 lh28 fw500 d-block pt-16 pb-38 pb-md-24"
                    },
                    [_vm._v(" Package Information ")]
                  )
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("b-table", {
                      staticClass: "dark-header new-order",
                      attrs: {
                        fields: _vm.fields,
                        items: _vm.form.items,
                        responsive: ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "head(dimensions)",
                          fn: function() {
                            return [
                              _c("span", { staticClass: "underline-dashed" }, [
                                _vm._v(" Dimensions ")
                              ]),
                              _c("i", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.right",
                                    value: "LxWxH in cm!!",
                                    expression: "'LxWxH in cm!!'",
                                    modifiers: { hover: true, right: true }
                                  }
                                ],
                                staticClass:
                                  "icon-tutorial ml-4 vam text-gray-400"
                              })
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "head(sku)",
                          fn: function() {
                            return [_vm._v(" SKU ")]
                          },
                          proxy: true
                        },
                        {
                          key: "head(quantity)",
                          fn: function() {
                            return [_vm._v(" QTY. ")]
                          },
                          proxy: true
                        },
                        {
                          key: "cell(selected)",
                          fn: function(ref) {
                            var item = ref.item
                            var key = ref.field.key
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center w-sm-50 align-items-center"
                                },
                                [
                                  _c("b-form-checkbox", {
                                    on: {
                                      input: function($event) {
                                        return _vm.deSelect($event)
                                      }
                                    },
                                    model: {
                                      value: item.selected,
                                      callback: function($$v) {
                                        _vm.$set(item, "selected", $$v)
                                      },
                                      expression: "item.selected"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(dimensions)",
                          fn: function(data) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("b-form-input", {
                                    staticClass: "small w40",
                                    attrs: {
                                      required:
                                        Object.keys(
                                          _vm.form.packetdiamention[0]
                                        ).length < 1,
                                      type: "number",
                                      min:
                                        Object.keys(
                                          _vm.form.packetdiamention[0]
                                        ).length < 1
                                          ? "1"
                                          : "0",
                                      max: "999",
                                      placeholder: "L"
                                    },
                                    model: {
                                      value: data.item.dimensions.packageLength,
                                      callback: function($$v) {
                                        _vm.$set(
                                          data.item.dimensions,
                                          "packageLength",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.item.dimensions.packageLength"
                                    }
                                  }),
                                  _c("span", { staticClass: "px-4" }, [
                                    _vm._v("x")
                                  ]),
                                  _c("b-form-input", {
                                    staticClass: "small w40",
                                    attrs: {
                                      required:
                                        Object.keys(
                                          _vm.form.packetdiamention[0]
                                        ).length < 1,
                                      type: "number",
                                      min:
                                        Object.keys(
                                          _vm.form.packetdiamention[0]
                                        ).length < 1
                                          ? "1"
                                          : "0",
                                      max: "999",
                                      placeholder: "W"
                                    },
                                    model: {
                                      value: data.item.dimensions.packageWidth,
                                      callback: function($$v) {
                                        _vm.$set(
                                          data.item.dimensions,
                                          "packageWidth",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.item.dimensions.packageWidth"
                                    }
                                  }),
                                  _c("span", { staticClass: "px-4" }, [
                                    _vm._v("x")
                                  ]),
                                  _c("b-form-input", {
                                    staticClass: "small w40",
                                    attrs: {
                                      required:
                                        Object.keys(
                                          _vm.form.packetdiamention[0]
                                        ).length < 1,
                                      type: "number",
                                      min:
                                        Object.keys(
                                          _vm.form.packetdiamention[0]
                                        ).length < 1
                                          ? "1"
                                          : "0",
                                      max: "999",
                                      placeholder: "H"
                                    },
                                    model: {
                                      value: data.item.dimensions.packageHeight,
                                      callback: function($$v) {
                                        _vm.$set(
                                          data.item.dimensions,
                                          "packageHeight",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.item.dimensions.packageHeight"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(weight)",
                          fn: function(data) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("b-form-input", {
                                    staticClass: "d-inline-flex small w72",
                                    attrs: {
                                      required:
                                        Object.keys(
                                          _vm.form.packetdiamention[0]
                                        ).length < 1,
                                      min:
                                        Object.keys(
                                          _vm.form.packetdiamention[0]
                                        ).length < 1
                                          ? "0.01"
                                          : "0.00",
                                      placeholder: "0.00",
                                      type: "number",
                                      step: "0.01"
                                    },
                                    model: {
                                      value: data.item.packageWeight,
                                      callback: function($$v) {
                                        _vm.$set(
                                          data.item,
                                          "packageWeight",
                                          $$v
                                        )
                                      },
                                      expression: "data.item.packageWeight"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(quantity)",
                          fn: function(data) {
                            return [
                              _c("b-form-input", {
                                staticClass: "small w50px",
                                attrs: {
                                  type: "number",
                                  required: "",
                                  placeholder: "0",
                                  min: "1"
                                },
                                model: {
                                  value: data.item.quantity,
                                  callback: function($$v) {
                                    _vm.$set(data.item, "quantity", $$v)
                                  },
                                  expression: "data.item.quantity"
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "cell(value)",
                          fn: function(data) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("b-form-input", {
                                    staticClass: "d-inline-flex small w72",
                                    attrs: {
                                      required:
                                        Object.keys(
                                          _vm.form.packetdiamention[0]
                                        ).length < 1,
                                      placeholder: "0.00",
                                      step: "0.01",
                                      min:
                                        Object.keys(
                                          _vm.form.packetdiamention[0]
                                        ).length < 1
                                          ? "0.01"
                                          : "0.00",
                                      type: "number"
                                    },
                                    model: {
                                      value: data.item.value,
                                      callback: function($$v) {
                                        _vm.$set(data.item, "value", $$v)
                                      },
                                      expression: "data.item.value"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ]),
              _vm.fields[2].key == "sku"
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "fs18 lh28 fw500 d-block pt-16 pb-38 pb-md-24"
                        },
                        [_vm._v(" Net Dimensions ")]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.fields[2].key == "sku"
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c("b-table", {
                          staticClass: "dark-header new-order",
                          attrs: {
                            fields: _vm.netfields,
                            items: _vm.form.packetdiamention,
                            responsive: ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "head(dimensions)",
                                fn: function() {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "underline-dashed" },
                                      [_vm._v(" Dimensions ")]
                                    ),
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover.right",
                                          value: "Net Dimensions LxWxH in cm",
                                          expression:
                                            "'Net Dimensions LxWxH in cm'",
                                          modifiers: {
                                            hover: true,
                                            right: true
                                          }
                                        }
                                      ],
                                      staticClass:
                                        "icon-tutorial ml-4 vam text-gray-400"
                                    })
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "cell(dimensions)",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex align-items-center"
                                      },
                                      [
                                        _c("b-form-input", {
                                          staticClass: "input-sm w85 mr-10",
                                          attrs: {
                                            required: "",
                                            type: "number",
                                            min: "1",
                                            max: "999",
                                            placeholder: "Length"
                                          },
                                          model: {
                                            value: data.item.itemLength,
                                            callback: function($$v) {
                                              _vm.$set(
                                                data.item,
                                                "itemLength",
                                                $$v
                                              )
                                            },
                                            expression: "data.item.itemLength"
                                          }
                                        }),
                                        _c("b-form-input", {
                                          staticClass: "input-sm w85 mr-10",
                                          attrs: {
                                            required: "",
                                            type: "number",
                                            placeholder: "Width",
                                            min: "1",
                                            max: "999"
                                          },
                                          model: {
                                            value: data.item.itemWidth,
                                            callback: function($$v) {
                                              _vm.$set(
                                                data.item,
                                                "itemWidth",
                                                $$v
                                              )
                                            },
                                            expression: "data.item.itemWidth"
                                          }
                                        }),
                                        _c("b-form-input", {
                                          staticClass: "input-sm w85 mr-10",
                                          attrs: {
                                            required: "",
                                            type: "number",
                                            placeholder: "Height",
                                            min: "1",
                                            max: "999"
                                          },
                                          model: {
                                            value: data.item.itemHeight,
                                            callback: function($$v) {
                                              _vm.$set(
                                                data.item,
                                                "itemHeight",
                                                $$v
                                              )
                                            },
                                            expression: "data.item.itemHeight"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              },
                              {
                                key: "cell(weight)",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex align-items-center"
                                      },
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "d-inline-flex input-sm w85",
                                          attrs: {
                                            required: "",
                                            placeholder: "0.00",
                                            min: "0.01",
                                            max: "999.99",
                                            type: "number",
                                            step: "0.01"
                                          },
                                          model: {
                                            value: data.item.itemWeight,
                                            callback: function($$v) {
                                              _vm.$set(
                                                data.item,
                                                "itemWeight",
                                                $$v
                                              )
                                            },
                                            expression: "data.item.itemWeight"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1131368257
                          ),
                          model: {
                            value: _vm.form.packetdiamention,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "packetdiamention", $$v)
                            },
                            expression: "form.packetdiamention"
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c(
                "b-button",
                {
                  staticClass: "mb-20",
                  attrs: {
                    id: "btnHide",
                    variant: "primary",
                    size: "lg",
                    disabled: _vm.disableSave,
                    type: "submit"
                  }
                },
                [_vm._v(" Submit ")]
              ),
              _c("choiceDialog", {
                ref: "choiceDialog",
                attrs: {
                  choiceShipmentData: _vm.choiceShipmentData,
                  formInfo: _vm.oldFormInfo,
                  choiceData: _vm.choiceData
                },
                on: { openModal: _vm.openPricingModal }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }