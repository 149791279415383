<template>
  <b-modal
    ref="editcalcpricing"
    id="modal-tall"
    size="lg"
    title="International Shipment"
    hide-footer
    okOnly
    v-model="isOpen"
    centered
    modal-class="pricing-modal"
  >
    <template v-slot:modal-header="{ close }">
      <span class="d-block fs18 lh28 fw500 mt-8">
        Price Chart
      </span>
      <!-- Emulate built in modal header close button action -->
      <b-button size="sm" variant="rounded" @click="close()">
        <i class="icon-close-variant"></i>
      </b-button>
    </template>

    <template v-slot:default>
    <div class="row justify-content-between pt-8 pt-md-26 pb-22">
      <div class="col-md-auto col-4">
        <span class="fs12 lh16 text-gray-600">
          Pickup
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
          {{ dialogHeader.originPin + ' - ' + dialogHeader.originCity }}
        </span>
      </div>

      <div class="col-md-auto col-4">
        <span class="fs12 lh16 text-gray-600">
          {{ dialogHeader.orderType }}
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
          <img src="@/assets/svg/arrow-right.svg" alt="" />
        </span>
      </div>

      <div class="col-md-auto col-4">
        <span class="fs12 lh16 text-gray-600">
          Delivery
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
          {{ dialogHeader.customerPinCode + ' - ' + dialogHeader.customerCity }}
        </span>
      </div>

      <div class="col-md-auto col-8 pt-28 pt-md-0">
        <span class="fs12 lh16 text-gray-600">
          Packet Value
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
          ₹ {{ parseFloat(dialogHeader.totalValue).toFixed(2) }}
        </span>
      </div>

      <div class="col-md-auto col-4 pt-28 pt-md-0">
        <span class="fs12 lh16 text-gray-600">
          Packet Weight
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
          {{ parseFloat(dialogHeader.packageWeight).toFixed(2) }} Kg
        </span>
      </div>

      <div class="col-md-auto col-12 pt-28 pt-md-0">
        <span class="fs12 lh16 text-gray-600">
          Packet dimensions
          <i
            class="icon-disclaimer fs12 text-gray-400"
            v-b-tooltip.hover.right
            title="Information about dimensions!"
          ></i>
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
          {{ dialogHeader.packageLength }}cm x {{  dialogHeader.packageWidth }}cm x {{ dialogHeader.packageHeight }}cm
        </span>
      </div>
    </div>

    <div class="row pt-6 pt-md-22">
      <div class="col">
        <b-table
          
          :fields="fields"
          :tbody-tr-class="tbodyRowClass"
          responsive
          :items="rowData"
          class="package-details-table"
          select-mode="single"
        >
          <!-- A custom formatted column -->
          <template v-slot:cell(carrier)="data">
            <img :src="getImgUrl(data.item.carrier)" class="mw100 carrier-logo">
          </template>

          <!-- A custom formatted column -->
          <template v-slot:cell(min_billing_weight)="data">
            Min. Weight: {{ data.item.minweight }} kg
          </template>

          <template v-slot:cell(shipping_mode)="data">
            {{ data.item.mode }}
          </template>

          <template v-slot:cell(estimated_delivery)="data">
            {{ data.item.tat }} Days
          </template>

          <!-- A custom formatted column -->
          <template v-slot:cell(estimated_price)="data">
            ₹{{ data.item.price }}
          </template>

          <template v-slot:cell(book_button)="data">
            <div style="width:max-content" v-if="(data.item.price > $store.state.global.overview.due) && $store.state.user.prepaid == 1">
              <b-link @click="openAddfunds" class="btn btn-primary" role="button">Add Funds</b-link>
               <i
                  class="icon-disclaimer ml-10 fs16 text-red"
                  v-b-tooltip.hover.top
                  title="Low Balance"
                ></i>
            </div>
            <div v-else>
              <b-link :disabled="data.item.disabled" @click="bookButton(data.item.mode, data.item.carrier, data.item.price)" class="btn btn-primary" role="button">Book</b-link>
            </div>
          </template>
        </b-table>
      </div>
      <BillingAddFunds ref="BillingAddFunds"></BillingAddFunds>
    </div>
    </template>
  </b-modal>
</template>

<script>
import BillingAddFunds from "@/components/BillingAddFunds.vue";
export default {
  name: "EditCalcPricing",
  props: ['modalOpen', 'formInfo', 'choiceData', 'bookOrderFlag','editShipment'],
  components:{
    BillingAddFunds
  },
  data() {
    return {
      selectedRow: null,
      isOpen: this.modalOpen,
      itemLength: null,
      fields: [
        {
          key: "carrier",
          label: "Carrier",
          sortable: true,
          thClass: "pl-4",
          tdClass: "pl-4",
        },
        {
          key: "min_billing_weight",
          label: "Min. Billing Weight",
          thClass: "wsnw",
        },
        {
          key: "shipping_mode",
          label: "Shipping Mode",
          sortable: true,
          thClass: "wsnw",
          tdClass: "wsnw",
        },
        {
          key: "estimated_delivery",
          label: "Est. Delivery",
          sortable: true,
          thClass: "wsnw",
        },
        {
          key: "estimated_price",
          label: "Est. Price",
          sortable: true,
          thClass: "wsnw",
        },
        {
          key: "book_button",
          label: "",
          sortable: true,
          thClass: "wsnw",
        },
      ],
      dialogHeader: {},
      rowData: [],
      form: {},
    };
  },

    watch: {
        isOpen: function(newValue) {
          if(!newValue) {
            this.$emit('update:editShipment', false)
          }
        },

        formInfo: {
            handler(newValue) {
                if(newValue.originPin) {
                  this.dialogData(newValue)
              }
            },
            deep: true 
        },

        choiceData: function(value) {
          if(Object.keys(value).length > 0) {
            this.choiceDialogData(value)
          }
        }

    },
  methods: {
    openAddfunds() {
      this.$refs.BillingAddFunds.isOpen = !this.$refs.BillingAddFunds.isOpen;
    },

    dialogData(form) {

        this.dialogHeader.originPin = form.originPin;
        this.dialogHeader.originCity = form.originCity;
        this.dialogHeader.orderType = form.orderType.toUpperCase();

        this.dialogHeader.customerPinCode = form.customerPinCode;
        this.dialogHeader.customerCity = form.customerCity;

        this.dialogHeader.totalValue = form.totalValue;

        this.dialogHeader.packageWeight = form.netDimension && form.netDimension.itemWeight ? form.netDimension.itemWeight : form.packageWeight;

        this.dialogHeader.packageLength = form.netDimension && form.netDimension.itemLength ? form.netDimension.itemLength : form.packageLength;

        this.dialogHeader.packageWidth = form.netDimension && form.netDimension.itemWidth ? form.netDimension.itemWidth : form.packageWidth;

        this.dialogHeader.packageHeight = form.netDimension && form.netDimension.itemHeight ? form.netDimension.itemHeight : form.packageHeight;

        this.itemLength = form.items.length

        this.form = form;

    },
    choiceDialogData(value) {

        let disableServiceArray = new Array();
        let enabledServiceArray = new Array();

        value.map(value => {
            if(value.length){
                value.map(val => {

                    if(val.disabled)
                        disableServiceArray.push(val);
                    else if (val.price == null)
                        return false
                    else
                        enabledServiceArray.push(val);
                })
            }
        })

        const completeArray = enabledServiceArray.concat(disableServiceArray)

        let valueArray = completeArray.filter( val => !isNaN(parseFloat(val.price)))

        const sorter = (a, b) => {
            return +a.price - +b.price;
        };

        let sortedPriceChart = valueArray.sort(sorter);

        this.rowData = sortedPriceChart.map((i) => {

            return {

                carrier: i.carrier || '',
                minweight: i.minweight,
                mode: this.staticName(this.$store.getters.modes, i.mode),
                price: i.price,
                service: i.service,
                serviceCode: i.serviceCode,
                tat: i.tat,
                disabled: i.disabled == true ? true : false,
            }
        })
    },
    staticName(statics, index) {
      return statics.filter(i => i.value == index)[0].name
    },

    staticValue(statics, name) {
      return statics.filter(i => i.name == name)[0].value
    },

    async bookButton(mode, carrier, price) {

      let data = Object.assign({}, this.form)


      if (this.editShipment) {
        data.modeType = this.staticValue(this.$store.getters.modes, mode)


        data.selectedCarrier = carrier

          data['warehouse'] = data.sellerAddressId.id
          data['pincode'] = data.customerPinCode
          data['estimatedamount'] = price
        
          try {

              let result = await this.axios.put(`/shipment/edit/${data.shipmentId}`, data);

              if (result.data.success) {
                  this.popToast("booked", "Success!", result.data.message);
                  this.$store.dispatch('dueUpdate')
                setTimeout(() => { 
                  this.$router.push('/shipments'); 
                }, 1000)
              } 
              else {
                this.popToast("failed", "Failed!", result.data.message);
              }
              this.isOpen = false;
              
          }
          catch(error) {
              this.popToast("failed", "Failed!", "Booking Failed!! Please Try Again Later");
          }
      } else {

          data.modeType = this.staticValue(this.$store.getters.modes, mode)

          data.selectedCarrier = carrier

            data.channel = "SINGLE"

            data.sellerAddressId = data.sellerAddressId.id
          
            try {

                let result = await this.axios.post(`/order/edit/${data.tableID}`, data);

                if (result.data.success) {
                    this.popToast("booked", "Success!", result.data.message);

                  setTimeout(() => { 
                    this.$router.push('/shipments'); 
                  }, 1000)
                } 
                else {
                  this.popToast("failed", "Failed!", result.data.message);
                  setTimeout(() => { 
                    window.location.reload(); 
                  }, 1000)
                  
                }
                this.isOpen = false;
                
            }
            catch(error) {
                this.popToast("failed", "Failed!", "Booking Failed!! Please Try Again Later");
            }
      }

      


    },

    tbodyRowClass(item) {
        if (item.selected) {
            return ["pricing-row-selected"];
        }
    },
    getImgUrl(carrier) {

      let images = require(`../assets/images/${carrier}.png`)
        
      return images
     
    },
    setValue: function(value) {
    },
  },
  created: function() {
    this.$parent.$on("openModal", this.setValue);
  },
};
</script>

<style scoped>
  .carrier-logo {
    max-height: 40px;
  }
</style>