<template>
  <div>
    <b-modal
    ref="bookReverseModal"
    id="modal-tall"
    size="lg"
    title="Book Reverse Shipment"
    v-model="isOpen"
    :hide-footer="true"
    modal-class="book-modal"
    >
    <template v-slot:modal-header="{ close }">
      <span class="d-block fs18 lh28 fw500 mt-8">
        Book-Reverse Shipment - {{ bookReverseShipmentData.order.importedID }}
      </span>
      <!-- Emulate built in modal header close button action -->
      <b-button size="sm" variant="rounded" @click="close()">
        <i class="icon-close-variant fs14"></i>
      </b-button>
    </template>
    <b-form @submit.prevent="formSubmit">
        <div class="row">
          <div class="col">
            <span class="fs18 lh28 fw500 d-block pb-38 pb-md-24">
              Order Information
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <b-form-group id="warehouse-field" label="Warehouse" label-for="warehouse" :description="form.sellerAddress ? form.sellerAddress.address : ''">
              <multiselect
              id="warehouse"
              ref="warehouse"
              v-model="form.sellerAddress"
              :options="rebookWarehouseOptions"
              label="name"
              :show-labels="false"
              ></multiselect>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <b-form-group id="orderid-field" label="Order ID" label-for="order-id" >
              <b-form-input
              id="order-id"
              placeholder="Enter order id"
              v-model="form.orderId"
              pattern="[0-9a-z#A-Z_ /-]*"
              minlength="2"
              maxlength="35"
              title="Allowed special characters are # - / _"
              required
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group id="date-field" label="Date" label-for="date">
              <date-picker 
                prefix-class="xmx" 
                ref="orderDate"
                v-model="form.orderDate" 
                id="date" 
                placeholder="yyyy-mm-dd" >
                <i slot="icon-calendar" class="icon-calendar"></i>
              </date-picker>
            </b-form-group>
          </div>
        </div>

        <div class="row">

          <div class="col-12 col-md-6">
            <b-form-group id="type-field" label="Order Type" label-for="Order Type">
              <multiselect
                id="type"
                value="REVERSE"
                :options="types"
                disabled
                readonly="true"
                :searchable="false"
                :show-labels="false"

              ></multiselect>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group id="total-value-field" label="Total Value" label-for="total-value" >
              <b-input-group append="₹">
                <b-input
                id="total-value"
                placeholder="0.00"
                v-model="form.totalValue"
                title="Only numeric or decimal values allowed"
                type="number"
                min="0.01"
                step="0.01"
                required
                ></b-input>
              </b-input-group>
            </b-form-group>
          </div>
        </div>

        <div class="row" v-if = "$store.getters.user.choice == 0">
          <div class="col-md-6">
            <b-form-group id="type-field" label="Mode Type" label-for="Mode Type">
              <multiselect
              id="type"
              label="name"
              v-model="form.modeType"
              required
              ref="modeType"
              :options="modeType"
              :searchable="false"
              :show-labels="false"
              ></multiselect>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <span class="fs18 lh28 fw500 d-block pt-16 pb-38 pb-md-24">
              Customer Information
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <b-form-group id="customer-name-field" label="Name" label-for="customer-name" >
              <b-form-input
              id="customer-name"
              placeholder="Enter customer name"
              v-model="form.customerName"
              required
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <b-form-group id="phone-field" label="Phone Number" label-for="phone-number" >
              <b-input-group>
                <b-form-input
                id="phone-number"
                placeholder="123 456 7890"
                type="tel"
                v-model="form.customerContact"
                maxlength="10"
                minlength="10"
                :formatter="numbers"
                oninvalid="this.setCustomValidity('Enter valid mobile number.')"
                oninput="this.setCustomValidity('')"
                required
                ></b-form-input>
                <div class="input-group-append">
                  <div class="input-group-text">
                    <i class="icon-phone fs16 text-gray-600"></i>
                  </div>
                </div>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group id="city-field" label="City" label-for="city">
              <b-form-input 
              id="city" 
              placeholder="Enter city"
              v-model="form.customerCity"
              maxlength="35"
              required
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row" v-if="this.maxLength">
          <div class="col-12 col-md-9">
            <b-form-group 
              id="address-field" 
              label="Full Address" 
              label-for="address" 
              :description="'Characters: ' + form.customerAddress.length + ' / ' + this.maxLength">
              <b-form-input
              id="address"
              placeholder="Enter customer address"
              v-model="form.customerAddress"
              required
              :maxlength="this.maxLength"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12 col-md-3">
            <b-form-group id="pin-field" label="Pin Code" label-for="pin">
              <b-form-input
              id="pin"
              placeholder="110001"
              type="text"
              maxlength="6"
              minlength="6"
              oninvalid="this.setCustomValidity('Enter valid pincode.')"
              oninput="this.setCustomValidity('')"
              :formatter="numbers"
              v-model="form.customerPinCode"
              required
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

      <div class="row">
        <div class="col">
          <span class="fs18 lh28 fw500 d-block pt-16 pb-38 pb-md-24">
            Package Information
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <b-table class="dark-header new-order" :fields="fields" :items="form.items" responsive >

            <!-- A custom formatted header cell for field 'dimensions' -->
            <template v-slot:head(dimensions)>
              <span class="underline-dashed">
                Dimensions
              </span>
              <i class="icon-tutorial ml-4 vam text-gray-400" v-b-tooltip.hover.right="'LxWxH in cm!!'" ></i>
            </template>
            <!-- A custom formatted header cell for field 'sku' -->
            <template v-slot:head(sku)>
              Categories
            </template>
            <template v-slot:head(sku)>
              SKU
            </template>
            <!-- A custom formatted header cell for field 'quantity' -->
            <template v-slot:head(quantity)>
              QTY.
            </template>

            <template v-slot:cell(selected)="{ item, field: { key }}">
              <div class="d-flex justify-content-center w-sm-50 align-items-center">
                <b-form-checkbox v-model="item.selected" @input="deSelect($event)"></b-form-checkbox>
              </div>
            </template>

            <!-- A custom formatted cell for field 'dimensions' -->
            <template v-slot:cell(dimensions)="data">
              <div class="d-flex align-items-center">
                <b-form-input
                v-model="data.item.dimensions.packageLength"
                :required="Object.keys(form.packetdiamention[0]).length < 1"
                type="number"
                v-bind:min="Object.keys(form.packetdiamention[0]).length < 1 ? '1' : '0'"
                max="999"
                placeholder="L"
                class="small w40"
                ></b-form-input>
                <span class="px-4">x</span>
                <b-form-input
                v-model="data.item.dimensions.packageWidth"
                :required="Object.keys(form.packetdiamention[0]).length < 1"
                type="number"
                v-bind:min="Object.keys(form.packetdiamention[0]).length < 1 ? '1' : '0'"
                max="999"
                placeholder="W"
                class="small w40"
                ></b-form-input>
                <span class="px-4">x</span>
                <b-form-input
                v-model="data.item.dimensions.packageHeight"
                :required="Object.keys(form.packetdiamention[0]).length < 1"
                type="number"
                v-bind:min="Object.keys(form.packetdiamention[0]).length < 1 ? '1' : '0'"
                max="999"
                placeholder="H"
                class="small w40"
                ></b-form-input>
              </div>
            </template>
            <!-- A custom formatted cell for field 'weight' -->
            <template v-slot:cell(weight)="data">
              <div class="d-flex align-items-center">
                <b-form-input
                v-model="data.item.packageWeight"
                :required="Object.keys(form.packetdiamention[0]).length < 1"
                v-bind:min="Object.keys(form.packetdiamention[0]).length < 1 ? '0.01' : '0.00'"
                placeholder="0.00"
                type="number"
                step="0.01"
                class="d-inline-flex small w72"
                ></b-form-input>
              </div>
            </template>
            <!-- A custom formatted cell for field 'quantity' -->
            <template v-slot:cell(quantity)="data">
              <b-form-input
              v-model="data.item.quantity"
              type="number"
              required
              placeholder="0"
              min="1"
              class="small w50px"
              ></b-form-input>
            </template>
            <!-- A custom formatted cell for field 'value' -->
            <template v-slot:cell(value)="data">
              <div class="d-flex align-items-center">
                <b-form-input
                v-model="data.item.value"
                :required="Object.keys(form.packetdiamention[0]).length < 1"
                placeholder="0.00"
                step="0.01"
                v-bind:min="Object.keys(form.packetdiamention[0]).length < 1 ? '0.01' : '0.00'"
                type="number"
                class="d-inline-flex small w72"
                ></b-form-input>
              </div>
            </template>
          </b-table>
        </div>
      </div>

      <div class="row" v-if="fields[2].key == 'sku'">
        <div class="col">
          <span class="fs18 lh28 fw500 d-block pt-16 pb-38 pb-md-24">
            Net Dimensions
          </span>
        </div>
      </div>

      <div class="row" v-if="fields[2].key == 'sku'">
        <div class="col">
          <b-table
          v-model="form.packetdiamention"
          class="dark-header new-order"
          :fields="netfields"
          :items="form.packetdiamention"
          responsive
          >

          <!-- A custom formatted cell for field 'dimensions' -->
          <template v-slot:head(dimensions)>
            <span class="underline-dashed">
              Dimensions
            </span>
            <i
            class="icon-tutorial ml-4 vam text-gray-400"
            v-b-tooltip.hover.right="'Net Dimensions LxWxH in cm'"
            ></i>
          </template>


          <template v-slot:cell(dimensions)="data">
            <div class="d-flex align-items-center">
              <b-form-input
              required
              v-model="data.item.itemLength"
              type="number"
              min="1"
              max="999"
              placeholder="Length"
              class="input-sm w85 mr-10"
              ></b-form-input>
              <b-form-input
              required
              v-model="data.item.itemWidth"
              type="number"
              placeholder="Width"
              min="1"
              max="999"
              class="input-sm w85 mr-10"
              ></b-form-input>
              <b-form-input
              required
              v-model="data.item.itemHeight"
              type="number"
              placeholder="Height"
              min="1"
              max="999"
              class="input-sm w85 mr-10"
              ></b-form-input>
            </div>
          </template>

          <!-- A custom formatted cell for field 'weight' -->
          <template v-slot:cell(weight)="data">
            <div class="d-flex align-items-center">
              <b-form-input
              required
              v-model="data.item.itemWeight"
              placeholder="0.00"
              min="0.01"
              max="999.99"
              type="number"
              step="0.01"
              class="d-inline-flex input-sm w85"
              ></b-form-input>
            </div>
          </template>

              </b-table>
          </div>
      </div>

      <b-button id="btnHide" variant="primary" size="lg" :disabled="disableSave" type="submit" class="mb-20" > Submit </b-button>
      <choiceDialog @openModal="openPricingModal" :choiceShipmentData="choiceShipmentData" :formInfo="oldFormInfo" :choiceData="choiceData" ref="choiceDialog"/>
    </b-form>
  </b-modal>
</div>
</template>

<script>
  import { VMoney } from "v-money";
  import DatePicker from "vue2-datepicker";
  import Multiselect from "vue-multiselect";
  import choiceDialog from "@/components/choiceDialog.vue";

  export default {
    name: "ShipmentBookReverse",
    props: ['modalOpen', 'bookReverseShipmentData', 'bookReverseAddress', ],
    data() {
      return {
        isOpen: this.modelOpen,
        disableSave: false,
        value: null,
        choiceData: [],
        maxLength: null,
        choiceShipmentData: {},
        oldFormInfo: {},
        allSelected: false,
        orderId: null,
        form: {
          warehouseName: {},
        },
        dialogData: {},
        warehouseName: [],
        editOrderWarehouses: [],
        rebookWarehouseOptions: [],
        sellerAddress: {
          address: null,
        },
        orderType: "",
        totalValue: "",
        customerName: "",
        customerPinCode: "",
        customerContact: "",
        customerAddress: "",
        types: ["PREPAID", "COD", "REVERSE"],
        items: [],
        netfields: [
          { key: "dimensions", label: "Dimensions" },
          {
            key: "weight",
            label:"Weight (Kg)",
            sortable: true,
          }
        ],
        fields: [
        {
          key:"selected",
          label:""
        },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "sku",
          sortable: true,
          class: "pl-0",
        },
        { key: "dimensions", label: "Dimensions" },
        {
          key: "weight",
          label: "Weight (Kg)",
          sortable: true,
        },
        {
          key: "quantity",
          sortable: true,
        },
        {
          key: "value",
          sortable: true,
          label: "Value (₹)",
          class: "pr-0",
        },
        ],
        money: {
          decimal: ",",
          thousands: ".",
          precision: 2,
          masked: false,
        },
      };
    },
    components: {
      DatePicker,
      Multiselect,
      choiceDialog,
    },
    directives: { money: VMoney },
    watch: {
      selectedRows: function() {
        if (this.selectedRows.length == this.items.length) {
          this.allSelected = true;
        }
      },
      bookReverseShipmentData: function(newValue) {
        if(newValue) {
          this.editWarehouses();
          this.robookForm(newValue)
          this.maxLength = 140
        }
      },
    },
    computed: {
      modeType() {
        const channel = this.bookReverseShipmentData && this.bookReverseShipmentData.order?.channel
        const channelName = this.bookReverseShipmentData && this.bookReverseShipmentData.order?.channelName

        if(channel && (channel == 9 || channel == 37 || channel == 12)) {
          return this.$store.getters.mode[channelName]
        }
        else{
          return this.$store.getters.mode.domestic
        }
      }
    },
    methods: {
      openPricingModal: function() {
              this.$refs.choiceDialog.isOpen = !this.$refs
                .choiceDialog.isOpen;
            },
      editWarehouses() {

        this.rebookWarehouseOptions = this.bookReverseAddress.map((i) => {
          return {
            name: i.name, 
            sellerAddress: i.id,
            address: i.address,
            $isDisabled: i.active == 1 && i.approved == 1 ? false: true,

          };
        })

      },
      computedFileds() {
        if(this.form.items && this.form.items[0].sku != null || this.form.items.length > 1){
          // this.fields
        }else{
          this.fields[2].key = 'category'
        }

        this.form.orderDate = new Date()

        this.form.modeType = this.$store.getters.modes.find(i => i.value == this.form.modeType)

      },
      async formSubmit() {

        if(!this.form.sellerAddress) {
          this.$refs.warehouse.$el.focus()
          return false
        }

        if (!this.form.orderDate) {
            this.$refs.orderDate.$el.querySelector("input").focus();
            return false;
        }

        if (this.$store.getters.user.choice) {

            this.form.bookReverse = true
            this.form.orderType = "REVERSE"

            if(this.form.items[0].category) {
                this.form.isCategory = true;
            }

            this.bookReverseAddress.filter(i => {

              if (this.form.sellerAddress && this.form.sellerAddress.sellerAddress) { 
                  if(i.approved && i.id == this.form.sellerAddress.sellerAddress) {
                    this.form.originPin = i.pincode
                    this.form.originCity = i.city
                  }
              }
              else {
                if(i.approved && i.id == this.form.sellerAddress.id) {
                    this.form.originPin = i.pincode
                    this.form.originCity = i.city
                  }
              }

            })

            let formData = this.form;

            let packageDimFlag = Boolean(formData.packetdiamention[0].itemHeight)

            this.form.packageHeight = packageDimFlag == true ? formData.packetdiamention[0].itemHeight : formData.items[0].dimensions.packageHeight

            this.form.packageLength = packageDimFlag == true ? formData.packetdiamention[0].itemLength : formData.items[0].dimensions.packageLength

            this.form.packageWeight = packageDimFlag == true ? formData.packetdiamention[0].itemWeight : formData.items[0].packageWeight

            this.form.packageWidth = packageDimFlag == true ?  formData.packetdiamention[0].itemWidth : formData.items[0].dimensions.packageWidth

            this.form.quantity =  packageDimFlag == true ? formData.items[0].quantity : formData.items[0].quantity
            
            if (formData.sellerAddress && formData.sellerAddress.sellerAddress) {
              this.form.sellerAddressId = formData.sellerAddress.sellerAddress
            }
            else {
              this.form.sellerAddressId = formData.sellerAddress.id
            }

            // sending only Checked items to Create New

            this.formDelete = Object.assign({}, formData);

            this.formDelete.items = this.formDelete.items.filter(i => i.selected == true)

            this.choiceShipmentData = Object.assign({}, this.formDelete)


            delete this.formDelete.items
            delete this.formDelete.sellerAddress

            this.oldFormInfo = this.formDelete

            try {

                let result = await this.axios.post('/choice/getchoicedata', this.formDelete);

                if (result.data.success == true) {
                    this.choiceData = result.data.choicepricing;
                    this.openPricingModal();
                }
                else if(result.data.success == false && result.data.hasOwnProperty('error')) {
                    this.popToast("failed", "Failed!", result.data.error);
                }
                else {
                    console.log(result.data)
                }
            }
            catch(err) {
                console.log(err)
            }

        }
        else {

            if(!this.form.modeType) {
              this.$refs.modeType.$el.focus()
              return false
            }

            this.form.channel = "SINGLE"
            this.form.orderType = "REVERSE"
            this.form.modeType = this.form.modeType.value

            if (this.form.sellerAddress && this.form.sellerAddress.sellerAddress) {
              this.form.sellerAddressId = this.form.sellerAddress.sellerAddress
            }
            else {
              this.form.sellerAddressId = this.form.sellerAddress.id
            }

            if(this.form.items[0].category) {
                this.form.isCategory = true;
            }


            // Selected item's new array
            let itemsArr = [];

            itemsArr = this.form.items.filter(i => i.selected == true)

            if (itemsArr.length == 0) {
                this.disableSave = true
            } else {
                this.disableSave = false
            }

            this.form.items = itemsArr

            let result = await this.axios.post(`/shipment/book/${this.form.tableID}/1`, this.form);

            this.isOpen = false;

            if (result.data.success == true && result.data.message) {
                this.popToast("booked", "Success!", result.data.message);

                setTimeout(() => { 
                  this.$router.push("/shipments?page=1&offset=25")
                }, 1000)

            }
            if(result.data.success == false && result.data.message) {
                this.popToast("failed", "Failed!", result.data.message);
            }
        }

      },
      toggleAllRows() {
        if (this.allSelected == false) {
          this.items.forEach((item) => {
            this.$set(item, "selected", true);
            this.allSelected = true;
          });
        } else {
          this.selectedRows.forEach((item) => {
            this.$delete(item, "selected");
            this.allSelected = false;
          });
        }
      },
      deSelect(value) {
        let defaultArr = []
        defaultArr = this.form.items.filter(i => i.selected == true)
        if (defaultArr.length == 0) {
          this.disableSave = true
        } else {
          this.disableSave = false
        }
      },
      async robookForm(value) {

        this.rebookFormData = {
        
          tableID: value.shipment.id,

          sellerAddress: {
            id: value.order.warehouseID,
            name: value.order.pickupDetails.name,
            pincode: value.order.pickupDetails.pincode,
            city: value.order.pickupDetails.city,
            address: value.order.pickupDetails.address,
          },

          orderId: '',
          orderType: value.order.type,
          modeType: value.order.mode,
          orderDate: value.order.date,
          totalValue: value.order.totalValue,
          customerName: value.order.customerData.name,
          customerPinCode: value.order.customerData.pin,
          customerCity: value.order.customerData.city,
          packetdiamention: [value.order.packetdiamention],
          customerContact: value.order.customerData.contact,
          customerAddress: value.order.customerData.address1 + ' ' + value.order.customerData.address2 + ' ' + value.order.customerData.address3 + ' ' + value.order.customerData.landmark,
          items: value.order.packageDetails.map(item => {
            return {
              fieldRequired: Object.keys(value.order.packetdiamention).length == 0 || item.category != null ? true : false,
              selected: true,
              category: item.category,
              sku: item.sku,
              name: item.name,
              dimensions: { packageWidth: item.width, packageHeight: item.height, packageLength: item.length },
              packageWeight: item.weight,
              quantity: item.quantity,
              value: item.price,

            }
          })
        }

        this.form = this.rebookFormData
        this.computedFileds()

        let formData = this.form

        this.form.sellerAddressId = this.rebookFormData.sellerAddress.id

        this.formDelete = Object.assign({}, formData);

                delete this.formDelete.sellerAddressId


      },
      async saveButton() {
        console.log(this.form)
      },
    },
  };
</script>
