var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", {
    ref: "editcalcpricing",
    attrs: {
      id: "modal-tall",
      size: "lg",
      title: "International Shipment",
      "hide-footer": "",
      okOnly: "",
      centered: "",
      "modal-class": "pricing-modal"
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function(ref) {
          var close = ref.close
          return [
            _c("span", { staticClass: "d-block fs18 lh28 fw500 mt-8" }, [
              _vm._v(" Price Chart ")
            ]),
            _c(
              "b-button",
              {
                attrs: { size: "sm", variant: "rounded" },
                on: {
                  click: function($event) {
                    return close()
                  }
                }
              },
              [_c("i", { staticClass: "icon-close-variant" })]
            )
          ]
        }
      },
      {
        key: "default",
        fn: function() {
          return [
            _c(
              "div",
              {
                staticClass: "row justify-content-between pt-8 pt-md-26 pb-22"
              },
              [
                _c("div", { staticClass: "col-md-auto col-4" }, [
                  _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                    _vm._v(" Pickup ")
                  ]),
                  _c(
                    "span",
                    { staticClass: "fs14 lh20 text-gray-900 mt-4 d-block" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dialogHeader.originPin +
                              " - " +
                              _vm.dialogHeader.originCity
                          ) +
                          " "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "col-md-auto col-4" }, [
                  _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                    _vm._v(" " + _vm._s(_vm.dialogHeader.orderType) + " ")
                  ]),
                  _c(
                    "span",
                    { staticClass: "fs14 lh20 text-gray-900 mt-4 d-block" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/svg/arrow-right.svg"),
                          alt: ""
                        }
                      })
                    ]
                  )
                ]),
                _c("div", { staticClass: "col-md-auto col-4" }, [
                  _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                    _vm._v(" Delivery ")
                  ]),
                  _c(
                    "span",
                    { staticClass: "fs14 lh20 text-gray-900 mt-4 d-block" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dialogHeader.customerPinCode +
                              " - " +
                              _vm.dialogHeader.customerCity
                          ) +
                          " "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "col-md-auto col-8 pt-28 pt-md-0" }, [
                  _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                    _vm._v(" Packet Value ")
                  ]),
                  _c(
                    "span",
                    { staticClass: "fs14 lh20 text-gray-900 mt-4 d-block" },
                    [
                      _vm._v(
                        " ₹ " +
                          _vm._s(
                            parseFloat(_vm.dialogHeader.totalValue).toFixed(2)
                          ) +
                          " "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "col-md-auto col-4 pt-28 pt-md-0" }, [
                  _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                    _vm._v(" Packet Weight ")
                  ]),
                  _c(
                    "span",
                    { staticClass: "fs14 lh20 text-gray-900 mt-4 d-block" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            parseFloat(_vm.dialogHeader.packageWeight).toFixed(
                              2
                            )
                          ) +
                          " Kg "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "col-md-auto col-12 pt-28 pt-md-0" }, [
                  _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                    _vm._v(" Packet dimensions "),
                    _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.right",
                          modifiers: { hover: true, right: true }
                        }
                      ],
                      staticClass: "icon-disclaimer fs12 text-gray-400",
                      attrs: { title: "Information about dimensions!" }
                    })
                  ]),
                  _c(
                    "span",
                    { staticClass: "fs14 lh20 text-gray-900 mt-4 d-block" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.dialogHeader.packageLength) +
                          "cm x " +
                          _vm._s(_vm.dialogHeader.packageWidth) +
                          "cm x " +
                          _vm._s(_vm.dialogHeader.packageHeight) +
                          "cm "
                      )
                    ]
                  )
                ])
              ]
            ),
            _c(
              "div",
              { staticClass: "row pt-6 pt-md-22" },
              [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("b-table", {
                      staticClass: "package-details-table",
                      attrs: {
                        fields: _vm.fields,
                        "tbody-tr-class": _vm.tbodyRowClass,
                        responsive: "",
                        items: _vm.rowData,
                        "select-mode": "single"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "cell(carrier)",
                          fn: function(data) {
                            return [
                              _c("img", {
                                staticClass: "mw100 carrier-logo",
                                attrs: { src: _vm.getImgUrl(data.item.carrier) }
                              })
                            ]
                          }
                        },
                        {
                          key: "cell(min_billing_weight)",
                          fn: function(data) {
                            return [
                              _vm._v(
                                " Min. Weight: " +
                                  _vm._s(data.item.minweight) +
                                  " kg "
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(shipping_mode)",
                          fn: function(data) {
                            return [_vm._v(" " + _vm._s(data.item.mode) + " ")]
                          }
                        },
                        {
                          key: "cell(estimated_delivery)",
                          fn: function(data) {
                            return [
                              _vm._v(" " + _vm._s(data.item.tat) + " Days ")
                            ]
                          }
                        },
                        {
                          key: "cell(estimated_price)",
                          fn: function(data) {
                            return [
                              _vm._v(" ₹" + _vm._s(data.item.price) + " ")
                            ]
                          }
                        },
                        {
                          key: "cell(book_button)",
                          fn: function(data) {
                            return [
                              data.item.price >
                                _vm.$store.state.global.overview.due &&
                              _vm.$store.state.user.prepaid == 1
                                ? _c(
                                    "div",
                                    { staticStyle: { width: "max-content" } },
                                    [
                                      _c(
                                        "b-link",
                                        {
                                          staticClass: "btn btn-primary",
                                          attrs: { role: "button" },
                                          on: { click: _vm.openAddfunds }
                                        },
                                        [_vm._v("Add Funds")]
                                      ),
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover.top",
                                            modifiers: {
                                              hover: true,
                                              top: true
                                            }
                                          }
                                        ],
                                        staticClass:
                                          "icon-disclaimer ml-10 fs16 text-red",
                                        attrs: { title: "Low Balance" }
                                      })
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c(
                                        "b-link",
                                        {
                                          staticClass: "btn btn-primary",
                                          attrs: {
                                            disabled: data.item.disabled,
                                            role: "button"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.bookButton(
                                                data.item.mode,
                                                data.item.carrier,
                                                data.item.price
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Book")]
                                      )
                                    ],
                                    1
                                  )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                ),
                _c("BillingAddFunds", { ref: "BillingAddFunds" })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ]),
    model: {
      value: _vm.isOpen,
      callback: function($$v) {
        _vm.isOpen = $$v
      },
      expression: "isOpen"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }