<template>
  <b-modal
    centered
    v-model="isOpen"
    title="Cancel Order"
    id="cancelOrderModal"
    ref="cancelOrderModal"
    modal-class="cancel-modal"
  >
    <template v-slot:modal-header="{ close }">
      <span class="fs18 lh28 fw500">
        Cancel Order
      </span>
      <b-button variant="rounded" @click="close()">
        <i class="icon-close-variant fw400"></i>
      </b-button>
    </template>
    <p class="pt-20 pt-md-20 text-gray-800">
      Are you sure you want to cancel this Order?
    </p>
    <template v-slot:modal-footer="{ close }">
      <b-button
        variant="secondary-modal w-sm-100 mb-12 mb-md-0"
        @click="cancelFromTable"
        class="m-0 mr-8"
      >
        Yes, cancel order
      </b-button>
      <b-button
        id="btnHide"
        variant="primary w-sm-100"
        @click="close()"
        class="m-0"
      >
        No, lead me back
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ShipmentsCancelModal",
  data() {
    return {
      isOpen: this.modalOpen,
    };
  },
  props: [ "modalOpen", "orderID" ],

  methods: {
    async cancelFromTable() {

      let result = await this.axios.post('/bulkcancel', {
          orders: [this.orderID]
      })

      if (result.data.success == true) {
        this.popToast(
          "booked",
          "success",
          "Order Cancelled Successfully"
        );
        // this.$parent.canceled()
        setTimeout(() => { 
          this.$router.push('/orders')
        }, 1000)

      }
      else{
        this.popToast(
          "failed",
          "Error!",
          result.data.error
        );
      }

      this.isOpen = false;
    },
    clearSelection: function(data) {
      if (this.$parent.clearSelected) {
        this.$parent.clearSelected();
      }
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
